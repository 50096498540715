import * as React from 'react'
import Getintouch from '../components/GetinTouch'
import Seo from '../components/seo'
import Layout from '../components/layout'
import WhoWeAre from '../components/WhoWeAre'
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
export default function ContactMediaCenter(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
          <WhoWeAre
            title={data.wpPage.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
        
         
          <Getintouch data={data?.wpPage?.inTheNews?.newsContactForm} locale={locale}/>
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query newsroomData($databaseId:Int) {
    
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    inTheNews {
    
      
      newsContactForm
    }
  }
}`);